import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { Modules } from '../../_json/modules';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { FluxStoreModuleCommonContent } from '../types';

const standorteSlice = createSlice<FluxStoreModuleCommonContent, SliceCaseReducers<FluxStoreModuleCommonContent>, Modules.Standorte, SliceSelectors<FluxStoreModuleCommonContent>>({
    name: Modules.Standorte,
    initialState: { rootContainer: undefined },
    reducers: {
        setRootContainer(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.rootContainer = action.payload;
            }
        },
    },
});

export const actions = standorteSlice.actions;
export const reducer = standorteSlice.reducer;
