import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { standardFilter, standardViewOptions } from '../defaults';
import { FluxStoreModuleContent } from '../types';
import { Modules } from '../../_json/modules';

const fahrzeugsucheSlice = createSlice<FluxStoreModuleContent, SliceCaseReducers<FluxStoreModuleContent>, Modules.Fahrzeugsuche, SliceSelectors<FluxStoreModuleContent>>({
    name: Modules.Fahrzeugsuche,
    initialState: { car: undefined, facets: {}, filter: standardFilter, list: {}, loading: false, numFound: 0, rootContainer: undefined, viewOptions: standardViewOptions },
    reducers: {
        setCar(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.car = action.payload;
            }
        },
        setFacets(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.facets = {
                    ...state.facets,
                    ...action.payload,
                };
            }
        },
        setFilter(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.filter = {
                    ...state.filter,
                    ...action.payload,
                };
            }
        },
        setList(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.list = action.payload;
            }
        },
        setNumFound(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.numFound = action.payload;
            }
        },
        setRootContainer(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.rootContainer = action.payload;
            }
        },
        setViewOptions(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.viewOptions = {
                    ...state.viewOptions,
                    ...action.payload,
                };
            }
        },
    },
});

export const actions = fahrzeugsucheSlice.actions;
export const reducer = fahrzeugsucheSlice.reducer;
