import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { Modules } from '../../_json/modules';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { FluxStoreModuleCommonContent } from '../types';

const schnellsucheSlice = createSlice<FluxStoreModuleCommonContent, SliceCaseReducers<FluxStoreModuleCommonContent>, Modules.Schnellsuche, SliceSelectors<FluxStoreModuleCommonContent>>({
    name: Modules.Schnellsuche,
    initialState: { rootContainer: undefined },
    reducers: {
        setRootContainer(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.rootContainer = action.payload;
            }
        },
    },
});

export const actions = schnellsucheSlice.actions;
export const reducer = schnellsucheSlice.reducer;
