import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Modules } from '../_json/modules';
import { reducer as aktionssliderReducer } from './slices/Aktionsslider';
import { reducer as commonReducer } from './slices/Common';
import { reducer as configListingReducer } from './slices/ConfigListing';
import { reducer as erweiterteSchnellsucheReducer } from './slices/ErweiterteSchnellsuche';
import { reducer as fahrzeuglisteReducer } from './slices/Fahrzeugliste';
import { reducer as fahrzeugsliderReducer } from './slices/Fahrzeugslider';
import { reducer as fahrzeugsucheReducer } from './slices/Fahrzeugsuche';
import { reducer as finanzierungsansichtReducer } from './slices/Finanzierungsansicht';
import { reducer as installReducer } from './slices/Install';
import { reducer as printReducer } from './slices/Print';
import { reducer as schnellsucheReducer } from './slices/Schnellsuche';
import { reducer as standorteReducer } from './slices/Standorte';
import { FluxStoreCommonContent, FluxStoreModuleContent } from './types';

export const store = configureStore<Omit<Record<Modules, FluxStoreModuleContent>, Modules.Contact> & { common: FluxStoreCommonContent }>({
    reducer: {
        [Modules.Aktionsslider]: aktionssliderReducer,
        common: commonReducer,
        [Modules.ConfigListing]: configListingReducer,
        [Modules.ErweiterteSchnellsuche]: erweiterteSchnellsucheReducer,
        [Modules.Fahrzeugliste]: fahrzeuglisteReducer,
        [Modules.Fahrzeugslider]: fahrzeugsliderReducer,
        [Modules.Fahrzeugsuche]: fahrzeugsucheReducer,
        [Modules.Finanzierungsansicht]: finanzierungsansichtReducer,
        [Modules.Install]: installReducer,
        [Modules.Print]: printReducer,
        [Modules.Schnellsuche]: schnellsucheReducer,
        [Modules.Standorte]: standorteReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export function useAppDispatch() { return useDispatch<AppDispatch>(); }
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
