import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { FluxStoreModuleCommonContent } from '../types';
import { Modules } from '../../_json/modules';

const fahrzeugsliderSlice = createSlice<FluxStoreModuleCommonContent, SliceCaseReducers<FluxStoreModuleCommonContent>, Modules.Fahrzeugslider, SliceSelectors<FluxStoreModuleCommonContent>>({
    name: Modules.Fahrzeugslider,
    initialState: { rootContainer: undefined },
    reducers: {
        setRootContainer(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.rootContainer = action.payload;
            }
        },
    },
});

export const actions = fahrzeugsliderSlice.actions;
export const reducer = fahrzeugsliderSlice.reducer;
