/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../utils/tools';
import configDefault from './_defaultValues';
import { ConfigObject } from './types';

const configLocal: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        debug: true,
        idFirma: 9800,
        enable: true,
        metaTitleSuffix: 'HOMEPAGE-TOOL',
        projectName: 'AHD Autohaus Digital',
        searchPath: '/fahrzeugsuche',
        searchPathDark: '/dark/fahrzeugsuche',
    },
    api: {
        ...configDefault.api,
        tracking: {
            ...configDefault.api.tracking,
            customScripts: {
                ...configDefault.api.tracking.customScripts,
                commonSuccess: false,
                formFinancingSuccess: 'alert(\'formFinancingSuccess\')',
                formGeneralSuccess: 'alert(\'formGeneralSuccess\')',
                formTestDriveSuccess: 'alert(\'formTestDriveSuccess\')',
                mailClick: 'alert(\'mailClick\')',
                mapsClick: 'alert(\'mapsClick\')',
                printClick: 'alert(\'printClick\')',
                phoneClick: 'alert(\'phoneClick\')',
                shareClick: 'alert(\'shareClick\')',
                tradeInClick: 'alert(\'tradeInClick\')',
            },
        },
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    cartile: {
        ...configDefault.cartile,
        badges: ['availableAt', 'aktion'],
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'standort', 'fd', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'standort', 'fd', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: true,
        useLocationMap: true,
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        erweiterteSchnellsuche: true,
        fahrzeugliste: true,
        fahrzeugslider: true,
        finanzierungsansicht: true,
        schnellsuche: true,
        standorte: true,
    },
    style: {
        ...configDefault.style,
        background: location?.pathname?.startsWith('/dark') ? '#373f43' : '#fff',
        borderRadius: 0,
        darkMode: location?.pathname?.startsWith('/dark'),
        primaryColor: location?.pathname?.startsWith('/dark') ? '#65b2e8' : '#0d1b48',
        muiComponents: (theme) => ({
            MuiSlider: {
                styleOverrides: {
                    markLabel: {
                        [getStyleQuery()]: {
                            fontSize: theme.typography.body2.fontSize,
                        },
                    },
                },
            },
        }),
    },
};

export default configLocal;
