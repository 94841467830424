import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { FluxStoreModuleCommonContent } from '../types';
import { Modules } from '../../_json/modules';

const finanzierungsansichtSlice = createSlice<FluxStoreModuleCommonContent, SliceCaseReducers<FluxStoreModuleCommonContent>, Modules.Finanzierungsansicht, SliceSelectors<FluxStoreModuleCommonContent>>({
    name: Modules.Finanzierungsansicht,
    initialState: { rootContainer: undefined },
    reducers: {
        setRootContainer(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.rootContainer = action.payload;
            }
        },
    },
});

export const actions = finanzierungsansichtSlice.actions;
export const reducer = finanzierungsansichtSlice.reducer;
